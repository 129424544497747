import * as React from "react";
import { Link } from "gatsby";

// markup
const NotFoundPage = () => {
  return (
    <p>Not found. <Link to="/">Go back home</Link>.</p>
  )
}

export default NotFoundPage
